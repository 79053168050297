.photos-container {
    display: flex;
    padding: 25px;
}

.icon-container {
    text-align: center;
    font-size: 13px;
    border-radius: 3px;
}

.i-container {
    margin: 10px;
    display: flex;
    flex-flow: column;
    align-items: center;
    opacity: 1;
    transition: opacity 2s;
    cursor: pointer;
}

.hidden-private {
    opacity: 0;
    pointer-events: none;
}

.photo-container {
    border: 2px #b4b4b4 solid;
    border-radius: 3px;
    box-shadow: 0px 0px 4px #8b8b8b;
}

.photos-icon {
    height: 100px;
    width: 140px;
    border-radius: 5px;
    margin: 2px;
    border-radius: 2px;
}

.private-container {

}

.queretaro {
    background-image: url(https://sues-website.s3.us-east-2.amazonaws.com/susan/EmptyApartment.png);
    background-size: cover;
}

.tree {
    background-image: url(https://sues-website.s3.us-east-2.amazonaws.com/susan/Tree.JPG);
    background-size: cover;
}

.icon-title {
    text-align: center;
    font-family: 'Helvetica';
    font-size: 13px;
    margin: 15px 0;
    width: fit-content;
    padding: 2px 8px;
    border-radius: 12px;
}

.fullscreen-c {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fullscreen-photo {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.folder {
    background-image: url('./assets/folder1.png');
    background-size: contain;
    height: 125px;
    width: 125px;
}

.private-container {
    overflow-y: auto;
    display: flex;
    justify-content: center;

}

.private-img {
    width: 200px;
    height: auto;
    margin: 20px;
    border-radius: 4px;
    box-shadow: 0 0 5px #464646;
    cursor: pointer;
}