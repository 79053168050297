@keyframes shake {
    0% { transform: translate(1px, 0); }
    10% { transform: translate(-1px, 0); }
    20% { transform: translate(-3px, 0); }
    30% { transform: translate(3px, 0); }
    40% { transform: translate(1px, 0); }
    50% { transform: translate(-1px, 0); }
    60% { transform: translate(-3px, 0); }
    70% { transform: translate(3px, 0); }
    80% { transform: translate(-1px, 0); }
    90% { transform: translate(1px, 0); }
    100% { transform: translate(1px, 0); }
}

body {
    font-family: HelveticaNeue, sans-serif;
}

.shake {
    animation: 1s shake;
}

.blur {
    width: 100%;
    height: 100%;
    filter: blur(10px);
    top: 0;
    left: 0;
    position: fixed;
}


.black-overlay {
    background-color: #00000040;
    width: 100%;
    height: 100%;
    position: fixed;
    position: fixed;
    z-index: 1;
}

.login-container {
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.password-input {
    height: fit-content;
    width: 150px;
    background-color: #cdcdcd7a;
    color: rgba(255, 255, 255, 0.664);
    border: none;
    border-radius: 3px;
    padding: 8px;
    font-size: 11px;
    font-family: 'LucidaG';
    font-family: 'HelveticaNeue';
    letter-spacing: 0.9px;    
    margin-top: 30px;
    transition: all 0.2s ease-in-out;
}

.password-input:focus {
    outline: none;
    box-shadow: 0px 0px 5px #bebebe;
}

.password-input::placeholder {
    color: rgba(255, 255, 255, 0.664);
}

#icons {
    margin-top: auto;
    margin-bottom: 20px;
}

.icon-text {
    color: #ffffffbf;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.8px;
    margin-top: 10px;
}

#accessibility {
    background-image: url(./assets/cc-02.png);
    height: 50px;
    width: 50px;
    background-size: contain;
    background-repeat: no-repeat;
}

.icon-container {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 11px;
    background-color: none;
    box-shadow: 0px;
    transition: .5s all;
}

.icon-container.activated {
    /* margin: 10px; */
    background-color: #ffffff1a;
    /* box-shadow: 0px 0px 10px #ffffff63; */
    border: 0.2px solid #ffffff5e;
    border-style: outset;
}

.icon-container:hover {
    cursor: pointer;
}

.user-icon {
    background-image: url('./assets/user.png');
    height: 80px;
    width: 80px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: auto;
}

.Login > .window {
    z-index: 1000;
    max-width: 400px;
    max-height: 200px;
}

.Login > .window {
    position: absolute;
    left: calc(50vw - 200px);
    top: calc(50vh - 100px);
    font-family: 'Helvetica';
    font-size: 14px;
}

.warning-icon {
    height: 75px;
    width: 75px;
    background-image: url('./assets/warning.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.warning-body {
    display: flex;
    justify-content: center;
    padding: 25px;
    align-items: center;
    height: 100%;
}

.warning-text {
    margin-left: 25px;
    line-height: 18px;
}

.warning-text > div {
    margin-top: 10px;
    font-size: 13px;
}

.login-input-container {
    display: flex;
    align-items: center;
}

.hint {
    opacity: 0;
    font-family: 'HelveticaNeue', sans-serif;
    color: #e0e0e0;
    margin-top: 8px;
    letter-spacing: 0.9px;
    font-size: 12px;
    transition: all 0.5s;
    margin-bottom: auto;

}

.hint.active {
    opacity: 1;
}