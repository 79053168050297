.glitch {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
}

.glitch-background {
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    position: absolute;
    background-image: url('./assets/glitch_background.gif');
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0;
}

@keyframes glitch-effect {
    0% {
        transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0) scale3d(-1,-1,1);
        -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
        clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
        background-color: #8d5d3d;
    }
    2% {
        -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
        clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
        background-color: #b44848;
    }
    4% {
        -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
        clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
        background-color: #ffc371;
    }
    5% {
        -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
        clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
        background-color: #8c54a0;
    }
    6% {
        -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
        clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
        background-color: #5474a0;
    }
    7% {
        -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
        clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
        background-color: #000000;
    }
    8% {
        -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
        clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
        background-color: #28a016;
    }
    9% {
        -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
        clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
        background-color: #224668;
    }
    9.9% {
        transform: translate3d(calc(-1 * var(--gap-horizontal)),0,0) scale3d(-1,-1,1);
        background-color: #a0166c;
    }
    10%, 100% {
        transform: translate3d(0,0,0) scale3d(1,1,1);
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        background-color: #a07316;
    }
}